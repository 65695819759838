<script>
import {mapGetters} from "vuex";
import {onBeforeWindowUnload} from '@/utils/beforeWindowUnload';

export default {
  name: 'NetworkErrorModal',
  props:{},
  data() {
    return {
      networkChecker: null,
      networkErrorModal: false,
    }
  },
  computed:{
    ...mapGetters(['appVisibility', 'timestampOnHidden', 'isOnline', 'isConnected', 'networkUnavailable', 'networkErrorCount', 'appHiddenTimeout']),
    hasNetworkError() {
      return !this.isOnline || !this.isConnected;
    },
  },
  watch: {
    appVisibility: {
      immediate: true,
      handler(state, _state) {
        const isDevelopment = process.env.NODE_ENV === 'development';
        const currentTime = new Date().getTime();

        if (currentTime - this.timestampOnHidden < this.appHiddenTimeout) {
          return;
        }

        !isDevelopment && state === 'visible' && _state === 'hidden' && this.refresh();
        state === 'visible' && _state === 'hidden' && this.refresh();
      }
    },
    networkUnavailable() {
      this.networkErrorModal = this.networkUnavailable;
    },
  },
  methods:{
    socketConnect(){
      //토큰값 갱신 + 소켓 재연결()
      if( !this.$socket.connected ){
        this.$socket.close();
        this.$socket.io.opts.refreshToken();
        this.$socket.connect();
      }
      return this.$socket.connected;
    },
    onCloseNetworkErrorModal() {
      this.networkErrorModal = false;
    },
    onConnect() {
      this.$store.commit('SET_IS_ONLINE', window.navigator.onLine);
      this.$store.commit('RESET_NETWORK_ERROR_COUNT');

      this.socketConnect();
      this.$forceUpdate();

      this.$nextTick(() => {
        this.checkNetworkStatus();
      });
    },
    onDisconnect() {
      this.$store.commit('SET_IS_ONLINE', window.navigator.onLine);
    },
    checkNetworkStatus() {
      this.networkChecker = this.networkChecker || setInterval(() => {
        if (this.hasNetworkError) {
          clearInterval(this.networkChecker);
        } else {
          //!this.isOnline && this.$store.commit('INCREASE_NETWORK_ERROR_COUNT');
        }

        this.$store.commit('SET_IS_NETWORK_UNSTABLE', this.hasNetworkError);

        //연결끊김 디버깅
        if (this.hasNetworkError) {
          console.log(`networkUnavailable: ${this.networkUnavailable}`);
          console.log(`hasNetworkError: ${this.hasNetworkError}`);
          console.log(`networkErrorCount: ${this.networkErrorCount}`);
          console.log(`isOnline: ${this.isOnline}, isConnected:${this.isConnected}`);
          console.log(this.playerSocketIssues);
        }
      }, 1500);
    },
    onSocketConnected() {
      this.$store.commit('SET_IS_CONNECTED', true);
      this.$store.commit('RESET_NETWORK_ERROR_COUNT');

      this.socketConnect();
      this.$forceUpdate();

      this.$nextTick(() => {
        this.checkNetworkStatus();
      });
    },
    onSocketDisconnected() {
      this.$store.commit('SET_IS_CONNECTED', false);
    },
    onSocketDuplicated() {
      //로그아웃 처리
      this.onLogout();

      //경고창 보여주기
      this.$swal.fire({
        title: '다른 브라우저에서 로그인했습니다',
        icon: "error",
      }).then(() => {
        clearInterval(this.networkChecker);
        this.releaseBeforeWindowUnload();
        if( !window.opener ){
          this.$router.replace({name: 'login'});
        }
      }).finally(() =>{
        window.close();
      });
    },
    onSocketCut() {
      //로그아웃처리
      this.onLogout();

      //경고창
      this.$swal.fire({
        title: '서버에 장애가 발생하여 접속이 종료됩니다. 재접속 해주시기 바랍니다.',
        icon: "error",
      }).then(() => {
        this.redirectToLobby();
      })
    },
    onLogout(){
      // sessionStorage.setItem(`loginToken`, undefined);
      // this.$store.state.loginToken = '';
      this.$store.dispatch('auth_logout');

      if (this.$socket.connected) {
        this.$socket.close();
        this.$socket.destroy();
      }
    },
    addEventBus() { //이벤트버스 등록
      this.EventBus.$on(`socket-duplicated`, this.onSocketDuplicated);
      this.EventBus.$on(`socket-cut`, this.onSocketCut)
    },
    removeEventBus(){ //이벤트버스 제거
      this.EventBus.$off(`socket-duplicated`, this.onSocketDuplicated);
      this.EventBus.$off(`socket-cut`, this.onSocketCut)
    },
    addEventSocket(){ //이벤트소켓 등록
      this.$socket.on(`connect`, this.onSocketConnected);
      this.$socket.on(`disconnect`, this.onSocketDisconnected);
    },
    removeEventSocket(){ //이벤트소켓 제거
      this.$socket.off(`connect`, this.onSocketConnected);
      this.$socket.off(`disconnect`, this.onSocketDisconnected);
    },
    addEventBrowser(){ //이벤트브라우저 등록
      window.addEventListener('beforeunload', onBeforeWindowUnload);
      window.addEventListener('online', this.onConnect);
      window.addEventListener('offline', this.onDisconnect);
    },
    removeEventBrowser(){ //이벤트브라우저 제거
      window.removeEventListener('beforeunload', onBeforeWindowUnload);
      window.removeEventListener('online', this.onConnect);
      window.removeEventListener('offline', this.onDisconnect);
    },
    releaseBeforeWindowUnload() {
      window.removeEventListener('beforeunload', onBeforeWindowUnload);
    },
    refresh() {
      this.releaseBeforeWindowUnload();
      window.location.reload();
    },
    logging(data, data2){
      console.log(`reconnect_error ${JSON.stringify(data)}, ${JSON.stringify(data2)}`);
    }
  },
  mounted(){
    //소켓 + 이벤트버스 + 브라우저 이벤트 등록
    this.addEventBrowser();
    this.addEventSocket();
    this.addEventBus();

    //토큰값 갱신 + 소켓 재연결()
    this.socketConnect();

    this.$store.commit('SET_IS_CONNECTED', this.$socket.connected);
    this.$store.commit('SET_IS_ONLINE', window.navigator.onLine);
    this.$store.commit('RESET_NETWORK_ERROR_COUNT');

    //네트워크 체커 등록
    this.checkNetworkStatus();
  },
  beforeDestroy() {
    //소켓 + 이벤트버스 + 브라우저 이벤트 제거
    this.removeEventBrowser();
    this.removeEventSocket();
    this.removeEventBus();

    //네트워크 체커 제거
    clearInterval(this.networkChecker);
  }
}
</script>

<template>
  <AppModal
      class="network-error-modal"
      :permanent="true"
      v-model="networkErrorModal"
      @close="onCloseNetworkErrorModal"
  >
    <div>
      <h1>네트워크 연결 끊김</h1>
      <div class="custom-content">
        <div class="modal-symbol">
          <span class="network-error"></span>
        </div>
        <p><b>네트워크 연결 이상</b>이 감지되었습니다.</p>
        <p>네트워크 연결 확인 후 <strong>새로고침</strong>을 해주세요.</p>
      </div>
    </div>
    <template #custom-action>
      <a type="button" class="refresh-action" @click.prevent.stop="refresh()">새로고침</a>
    </template>
  </AppModal>
</template>

<style scoped lang="scss">
.network-error-modal h1 {
  font-size: 2rem;
}

.network-error-modal p {
  font-size: 14px;
}

.refresh-action {
  color: #1C87EA;
  font-weight: bold;
}

.refresh-action:hover {
  font-weight: bold;
}

.modal-symbol {
  height: 8rem;
  width: 8rem;
  margin: 2rem auto;

  border-radius: 8rem;
  border: thick #444 solid;
}

.custom-content b {
  color: #d2214c;
}

.custom-content strong {
  cursor: pointer;
}

.custom-content strong:hover {
  color: #1C87EA;
  text-decoration: underline;
}

.network-error {
  display: inline-block;
  width: 8rem;
  height: 8rem;
  margin: 0 auto;
  background-size: 5.5rem;
  background-position: 0.8rem 1.4rem;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23444' class='bi bi-plug-fill'%3E%3Cpath d='M6 0a.5.5 0 0 1 .5.5V3h3V.5a.5.5 0 0 1 1 0V3h1a.5.5 0 0 1 .5.5v3A3.5 3.5 0 0 1 8.5 10c0 .43-.01.84-.04 1.22-.04.51-.13 1-.32 1.42a2.08 2.08 0 0 1-.97 1.03c-.45.23-1 .33-1.67.33-1 0-1.61.33-1.97.72A1.92 1.92 0 0 0 3 16H2c0-.62.23-1.37.8-1.97A3.57 3.57 0 0 1 5.5 13c.58 0 .96-.09 1.22-.22.24-.12.4-.3.51-.55.12-.27.2-.62.23-1.09.03-.34.04-.72.04-1.14A3.5 3.5 0 0 1 4 6.5v-3a.5.5 0 0 1 .5-.5h1V.5A.5.5 0 0 1 6 0z'/%3E%3C/svg%3E");
}
</style>